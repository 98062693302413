import $ from 'jquery'

export default function initHome() {
  $(document).ready(initHomeSlider)
  function initHomeSlider() {
    if ($('.js-home-slider').length) {
      $('.js-home-slider').slick({
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 6500,
        speed: 700
      });
    }
  }
}