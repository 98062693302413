import $ from 'jquery'

const $doc = $(document)

function expandCollapse(event) {
  let el = $(this);
  el.toggleClass('is-active')
  if ( el.hasClass('is-active')) {
    el.attr('aria-expanded', 'true')
    el.text('- Collapse')
  } else {
    el.attr('aria-expanded', 'false')
    el.text('+ Expand')
  }
  el.next('div').slideToggle()
}

export default function initExpandCollapse(){
  $doc.on('click', '.js-expand-collapse', expandCollapse )
}
